<template>
  <div id="vacationRequestView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>{{ isEditPage ? 'edit vacation request entries' : 'vacation request entry' }}</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <fieldset v-if="!isCompleted" v-bind:disabled="disableFields">
          <employee-autocomplete v-if="isEditable && !isEditPage" v-show="currentUserCanRaiseRequestForOtherRegularEmployees || currentUserCanRaiseRequestForExpatriates" :items="filteredSearchedEmployees" id="emp_search" :sData="employeeInfo.fullName" v-bind:isShown="true" isRequired />
          <div>
            <employee-info-display v-if="employeeInfo.employeeId" :employeeInfo="employeeInfo" :vacationMaster="vacationMaster" :requestCanBeRaisedForEmployee="leaveRequestCanBeRaisedForEmployee" :additionalData="additionalData" :unScheduledVacationDaysToDisplay="unScheduledVacationDaysToDisplay" :unScheduledRnRDaysToDisplay="vacationMaster.unScheduledRnRDays" :displayMode="!leaveRequestCanBeRaisedForEmployee" :showVacationYearSelect="isNewRequestPage"></employee-info-display>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row" v-if="leaveRequestCanBeRaisedForEmployee">
      <div class="col">
        <div v-if="!showOptionsForWS14x14">
          <div class="clearfix">
            <a v-if="previouslyScheduledVacationRequests.length" class="float-right" :title="showPreviousRequests ? '- Hide Previously Scheduled Vacation(s)?' : '- Show Previously Scheduled Vacation(s)?'" @click="showPreviousRequests = !showPreviousRequests">{{ showPreviousRequests ? 'Click to Hide Previously Scheduled Vacation(s)' : 'Click to Show Previously Scheduled Vacation(s)' }}</a>
          </div>
          <fieldset v-bind:disabled="disableFields">
            <form id="vacationRequestForm" novalidate onsubmit="event.preventDefault();">
              <table>
                <thead>
                  <tr>
                    <th style="width:15% !important;">start date</th>
                    <th style="width:23% !important;"></th>
                    <th style="width:10%;">days</th>
                    <th style="width:10%;">holidays</th>
                    <th style="width:15%;">resumption</th>
                    <th style="width:23% !important;"></th>
                    <th style="width:4%; text-align:center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr is="VacationRequestEntryDisplay" v-if="showPreviousRequests" v-for="request in previouslyScheduledVacationRequests" v-bind:request="request" :showEditButton="$route.params.id && canEditRequest(request)"></tr>
                  <tr is="VacationRequestEntry" v-for="(request, index) in requests" :request="request" :requests="requests" :previouslyScheduledVacationRequests="previouslyScheduledVacationRequests" :employee="employeeInfo" :vacationYear="vacationMaster.vacationYear" v-bind:key="request.id"></tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="7" class="text-right font-weight-bolder" v-show="allowAddNewRequest"><span class="action-btn mr-2 font-weight-bold h5" @click="tryAddNewRequest">&#9545;</span></td>
                  </tr>
                </tfoot>
              </table>
              <div v-if="canStillRaiseCashInLieu">
                <router-link :to="{ name: 'enterCashInLieuOfVacationRequest', params: { employeeId: employeeInfo.employeeId || currentUser.employeeId, vacationYear: vacationMaster.vacationYear }}">request cash in-lieu instead</router-link>
              </div>
              <div class="form-group col-md-12 mt-4 text-center">
                <employee-autocomplete label="approver" errorLabel="Please, select a valid approver" :items="searchedApprovers" id="supv_search" :sData="intendedApproverName" :employeeSearchClearedEvent="approverSearchClearedEvent" :employeeSearchStartedEvent="approverSearchStartedEvent" :employeeSelectedEvent="approverSelectedEvent" v-bind:isShown="true" isRequired :isDisabled="disableFields || isSearchingEmployees" />
                <!--<span>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-question-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.57 6.033H5.25C5.22 4.147 6.68 3.5 8.006 3.5c1.397 0 2.673.73 2.673 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.355H7.117l-.007-.463c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.901 0-1.358.603-1.358 1.384zm1.251 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z">
                    <title>DOA and Back-To-Back is automatically applied</title>
                  </path>
                </svg>
              </span>-->
              </div>
            </form>
          </fieldset>
          <div class="col-md-12 mt-4 text-center" v-if="isEditable">
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingRequest }" @click="submitRequest" v-bind:disabled="isSavingRequest">Submit<span class="spinner"></span></button>
          </div>
        </div>
        <div v-else>
          <button class="btn btn-primary d-inline mr-2" @click="generateVacationData" v-bind:disabled="isGeneratingVacationData">Generate vacation data</button>
          Or <router-link :to="{ name: 'enterCashInLieuOfVacationRequest', params: { employeeId: employeeInfo.employeeId || currentUser.employeeId, vacationYear: vacationMaster.vacationYear }}">request cash in-lieu</router-link>
          <!--Or <router-link :to="'/requests/vacation/cashinlieu/?employeeId='+ employeeInfo.employeeId">request cash in-lieu</router-link>-->
        </div>
      </div>
    </div>
    <div v-if="isCompleted && isNewRequestPage" class="text-right">
      <button class="btn btn-primary d-inline mr-2" @click="startNewRequest">New Request</button>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .action-btn {
    cursor: pointer;
    font-size: 30px;
  }

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>
  import store from '@/store'
  import { mapState } from 'vuex'

  import { FETCH_CODETYPES, FETCH_PUBLICHOLIDAYS, SCHEDULE_LEAVE_REQUEST } from '@/store/action-type'

  import { v4 as uuidv4 } from 'uuid';
  import Enums from '@/utils/enums'
  import { fromServerDate } from '@/utils/date'
  import ErrorMessages from '@/utils/errors/messages'

  import { employeeService, vacationMasterService, cashInLieuRequestService, studyLeaveRequestService, leaveOfAbsenceRequestService } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import { EDIT_REQUEST_EVENT } from '@/utils/constants'


  import VacationRequestEntry from '@/components/vacation/SingleEntry'
  import VacationRequestEntryDisplay from '@/components/vacation/SingleEntryDisplay'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'
  import LeaveRequestActionsMixin from '@/mixins/leaveRequest/requestActions'

  export default {
    name: 'VacationRequestView',
    components: {
      VacationRequestEntry,
      VacationRequestEntryDisplay
    },
    mixins: [GeneralMixin, AccessControlMixin, LeaveRequestMixin, LeaveRequestActionsMixin],
    data: () => ({
      requests: [],
      vacationMaster: {
        id: null,
        vacationYear: null,
        vacationDaysDue: 0,
        travelDays: 0,
        vacationAndTravelDays: 0,
        carriedOverDays: 0,
        unScheduledVacationDays: 0,
        unscheduledRnRDays: 0,
        unscheduledCarriedOverDays: 0,
        buyOutDays: 0,
        vacationAllowanceRequest: {
          id: null,
          payMonth: 12,
          paymentStatus: 0,
          allowanceType: 0,
          version: null
        },
        cashInLieuRequests: [],
        existingVacationRequests: [],
        version: null
      },
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
        employmentDate: '',
        sbuStartDate: '',
        locationName: '',
        locationId: '',
        workScheduleName: '',
        workScheduleId: '',
        employeeTypeId: '',
        employeeTypeName: '',
        supervisorName: '',
        payrollCountryId: null,
        payrollCountry: null,
        alternateSupervisorName: '',
        isRegularEmployee: false,
        isExpatriateEmployee: false,
        isFieldEmployee: false,
        isResidentExpatriateEmployee: false,
        isShiftEmployee: false,
        isOnForeignService: false
      },
      additionalData: {
        vacationYears: [],
        startingUscheduledVacationDays: 0,
        startingUnscheduledCarryOverDays: 0
      },
      intendedApproverId: '',
      intendedApproverName: '',
      previouslyScheduledVacationRequests: [],
      showPreviousRequests: false,
      showOptionsForWS14x14: false,
      isGeneratingVacationData: false,
      requestType: Enums.RequestType.Vacation.value
    }),
    computed: {
      ...mapState({
        appSettings: state => state.applicationSettingsModule.appSettings,
      }),
      filteredSearchedEmployees() {
        //if (this.searchedEmployees && this.searchedEmployees.length) {
        //  return this.searchedEmployees.filter(employee => employee.isRegularEmployee || employee.isExpatriateEmployee)
        //}
        //return []
        return this.searchedEmployees
      },
      unScheduledVacationDaysToDisplay() {
        let uscheduledVacationDays = this.additionalData.startingUscheduledVacationDays
        let unscheduledCarriedOverDays = this.additionalData.startingUnscheduledCarryOverDays

        if (this.employeeInfo.isFieldEmployee) {
          unscheduledCarriedOverDays = 0
        }

        for (const request of this.requests) {
          if (request.startDate && request.duration) {
            const startDate = new Date(Date.parse(request.startDate))
            let durationToDeduct = request.duration
            const month = startDate.getMonth() + 1
            if (((this.employeeInfo.isRegularEmployee && !this.employeeInfo.isFieldEmployee && month <= this.appSettings.vacation.lastMonthForVacationCarryOver) || this.employeeInfo.isExpatriateEmployee) && unscheduledCarriedOverDays > 0) {
              if (unscheduledCarriedOverDays > request.duration) {
                unscheduledCarriedOverDays = unscheduledCarriedOverDays - request.duration
                durationToDeduct = 0
              }
              else {
                durationToDeduct = request.duration - unscheduledCarriedOverDays
                unscheduledCarriedOverDays = 0
              }
            }
            uscheduledVacationDays = uscheduledVacationDays - durationToDeduct
          }
        }

        this.vacationMaster.unscheduledVacationDays = uscheduledVacationDays
        this.vacationMaster.unscheduledCarriedOverDays = unscheduledCarriedOverDays
        //return this.additionalData.startingUscheduledVacationDays - this.requests.reduce((a, b) => a + (b['duration'] || 0), 0);
        return uscheduledVacationDays + unscheduledCarriedOverDays
      },
      allowAddNewRequest() {
        return /*!this.employeeInfo.isFieldEmployee && */this.unScheduledVacationDaysToDisplay > 0
      },
      leaveRequestCanBeRaisedForEmployee() {
        // An employee has been selected
        if (this.employeeInfo.employeeId && this.isEditable) {
          // Only regular field employees not on foreign service can raise a field vacation option scheduling request
          if (this.employeeInfo.isResidentExpatriateEmployee) {
            if (this.employeeInfo.isOnForeignService) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.EmployeeOnForeignService, this.requestType)
              return false
            }
            if (!this.appSettings.vacation.allowPreAnniversaryScheduling && (new Date(this.employeeInfo.employmentDate)).getFullYear() === (new Date()).getFullYear()) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToPreAnniversary, this.requestType)
              return false
            }
            //if (this.employeeInfo.isFieldEmployee && this.previouslyScheduledVacationRequests.length) {
            //  this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.MaximumNumberOfVacationForFieldEmployees, this.requestType)
            //  return false
            //}
            const today = new Date()
            if (this.vacationMaster.id && today.getFullYear() > this.vacationMaster.vacationYear) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.VacationCannotBeEditedForPreviousYears, this.requestType)
              return false
            }
            if (this.vacationMaster.id && this.employeeInfo.workScheduleId == Enums.WorkSchedule.WS28x28.value) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.EmployeesOn28DaysScheduleCannotScheduleVacation, this.requestType)
              //this.errorMessageIfRequestCannotBeRaisedForEmployee = 'There was an error opening this page. The system administrators are currently working to fix this error. Kindly try again later'
              return false
            }
            if (!this.vacationMaster.id && !this.showOptionsForWS14x14) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
              return false
            }
            //if (this.vacationMaster.id && this.vacationMaster.cashInLieuRequests.length) {
            //  this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.ValidCashInLieuRequestAlreadyExists, this.requestType)
            //  return false
            //}
            if (this.employeeInfo.isFieldEmployee && this.vacationMaster.id && this.additionalData.startingUscheduledVacationDays < 1) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.NoUnscheduledVacationDays, this.requestType)
              return false
            }
            if (!this.isFieldEmployee && this.vacationMaster.id && (this.additionalData.startingUscheduledVacationDays + this.additionalData.unscheduledCarriedOverDays < 1))
              if (this.vacationMaster.id && this.unScheduledVacationDaysToDisplay < 0 && !this.isEditPage) {
                this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.NoUnscheduledVacationDays, this.requestType)
                return false
              }
            if (this.vacationMaster.id && this.vacationMaster.unscheduledVacationDays < 0 && !this.isEditPage) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CarriedOverDaysCanOnlyBeUsedOnOrBeforeLastMonthForVacationCarryOver, this.requestType)
              return true
            }
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
            return true
          }
          else {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.InvalidEmployeeType, this.requestType)
            return false
          }
        }
        else {
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return false
        }
      },
      canStillRaiseCashInLieu() {
        return this.employeeInfo.isFieldEmployee && this.employeeInfo.workScheduleId == Enums.WorkSchedule.WS14x14.value && this.vacationMaster.id && !this.vacationMaster.existingVacationRequests.filter((vr) => !vr.isCancelled).length
      }
    },
    watch: {
      'vacationMaster.vacationYear': {
        immediate: false,
        handler(newValue, oldValue) {
          if (this.employeeInfo.employeeId) {
            this.fetchEmployeeData(this.employeeInfo)
          }
        }
      },
      requests: {
        deep: true,
        immediate: false,
        handler(newValue, oldValue) {
          if (!newValue.length) {
            this.$nextTick(() => {
              this.tryAddNewRequest()
            })
          }
        }
      },
      'intendedApproverId': {
        immediate: false,
        handler(newValue, oldValue) {
          if (this.requests.length) {
            const self = this
            this.requests.forEach(function (request) {
              request.intendedApproverId = self.intendedApproverId
            })
          }
        }
      },
      'intendedApproverName': {
        immediate: false,
        handler(newValue, oldValue) {
          if (this.requests.length) {
            const self = this
            this.requests.forEach(function (request) {
              request.intendedApproverName = self.intendedApproverName
            })
          }
        }
      }
    },
    methods: {
      resetData() {
        this.resetEmployeeData()
        this.employeeInfo.employmentDate = ''
        this.employeeInfo.sbuStartDate = ''
        this.employeeInfo.payrollCountryId = ''
        this.employeeInfo.payrollCountry = ''
        this.employeeInfo.isShiftEmployee = false
        this.employeeInfo.isResidentExpatriateEmployee = false
        this.intendedApproverId = ''
        this.intendedApproverName = ''
        this.vacationMaster.id = null
        this.vacationMaster.vacationDaysDue = 0
        this.vacationMaster.travelDays = 0
        this.vacationMaster.vacationAndTravelDays = 0
        this.vacationMaster.carriedOverDays = 0
        this.vacationMaster.unScheduledVacationDays = 0
        this.vacationMaster.unscheduledRnRDays = 0
        this.vacationMaster.buyOutDays = 0
        this.vacationMaster.fieldVacationOption = ''
        this.vacationMaster.vacationAllowanceRequest.id = null
        this.vacationMaster.vacationAllowanceRequest.payMonth = 12
        this.vacationMaster.vacationAllowanceRequest.paymentStatus = 0
        this.vacationMaster.vacationAllowanceRequest.allowanceType = 0
        this.vacationMaster.vacationAllowanceRequest.version = null
        this.vacationMaster.cashInLieuRequests.splice(0, this.vacationMaster.cashInLieuRequests.length)
        this.vacationMaster.existingVacationRequests.splice(0, this.vacationMaster.existingVacationRequests.length)
        this.vacationMaster.version = null
        this.additionalData.startingUscheduledVacationDays = 0
        this.additionalData.startingUnscheduledCarryOverDays = 0
        this.previouslyScheduledVacationRequests.splice(0, this.previouslyScheduledVacationRequests.length)
        this.requests.splice(0, this.requests.length)
      },
      clearApprover() {
        this.intendedApproverId = ''
        this.intendedApproverName = ''
      },
      selectApprover(approver) {
        this.intendedApproverId = approver.employeeId
        this.intendedApproverName = approver.fullName
      },
      fetchEmployeeData(employee) {
        const self = this
        self.closeMessage()
        eventBus.$emit(Events.LongOperationStarted, '')
        let tasks = []
        tasks.push(employeeService.getEmployeeWithWorkType(employee.employeeId, true))
        if (self.isEditPage) {
          tasks.push(vacationMasterService.getVacationMasterForVacationRequest(self.$route.params.id))
        }
        else {
          tasks.push(vacationMasterService.getVacationMasterFor(employee.employeeId, self.vacationMaster.vacationYear))
        }
        //tasks.push(vacationRequestService.getVacationRequestsFor(employee.employeeId, self.vacationYear))
        Promise.all(tasks).then((results) => {
          self.resetData()
          self.closeMessage()
          const employee = results[0].data
          var vacationMaster = results[1]
          if (self.isEditPage && !vacationMaster.vacationRequests.filter((request) => request.id == self.$route.params.id).length) {
            self.showErrorMessage('Request not found')
            eventBus.$emit(Events.LongOperationCompleted)
          }
          else {
            if (self.isEditPage) {
              var theEmployeeInfo = vacationMaster.vacationRequests.filter((request) => request.id == self.$route.params.id)[0].employeeInfo
              theEmployeeInfo.isResidentExpatriateEmployee = employee.isResidentExpatriateEmployee
              self.setData(theEmployeeInfo, vacationMaster)
            }
            else {
              self.setData(employee, vacationMaster/*, previousSchedules*/)
            }
          }
          //var previousSchedules = results[2].items
        }).catch((error) => {
          console.log(error)
          self.handleError(error)
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      setData(employee, vacationMaster) {
        this.setEmployeeData(employee)
        this.employeeInfo.employmentDate = employee.employmentDate
        this.employeeInfo.sbuStartDate = employee.sbuStartDate
        this.employeeInfo.payrollCountryId = employee.payrollCountryId
        this.employeeInfo.payrollCountry = employee.payrollCountry
        this.employeeInfo.isShiftEmployee = employee.isShiftEmployee
        this.employeeInfo.isResidentExpatriateEmployee = employee.isResidentExpatriateEmployee
        this.intendedApproverId = employee.supervisorId
        this.intendedApproverName = employee.supervisorName
        if (vacationMaster) {
          this.setVacationData(vacationMaster)
        }
        else {
          eventBus.$emit(Events.LongOperationCompleted)
          if (employee.workScheduleId != Enums.WorkSchedule.WS14x14.value) {
            this.showOptionsForWS14x14 = false
            if (employee.workScheduleId == Enums.WorkSchedule.WS28x28.value) {
              /*this.generateCashInLieuRequest()*/
              this.$router.push({ name: 'enterCashInLieuOfVacationRequest', params: { employeeId: employee.employeeId, vacationYear: this.vacationMaster.vacationYear } })
            }
            else {
              this.generateVacationData()
            }
          }
          else if (employee.isRegularEmployee && employee.workScheduleId == Enums.WorkSchedule.WS14x14.value) {
            this.showOptionsForWS14x14 = true
          }
        }
      },
      setVacationData(vacationMaster/*, previousSchedules*/) {
        this.vacationMaster.id = vacationMaster.id
        this.vacationMaster.vacationDaysDue = vacationMaster.vacationDaysDue
        this.vacationMaster.travelDays = vacationMaster.travelDays
        this.vacationMaster.vacationAndTravelDays = vacationMaster.vacationAndTravelDays
        this.vacationMaster.carriedOverDays = vacationMaster.carriedOverDays
        this.vacationMaster.unScheduledVacationDays = this.additionalData.startingUscheduledVacationDays = vacationMaster.unscheduledVacationDays
        this.vacationMaster.unscheduledCarriedOverDays = this.additionalData.startingUnscheduledCarryOverDays = vacationMaster.unscheduledCarriedOverDays
        this.vacationMaster.unscheduledRnRDays = vacationMaster.unscheduledRnRDays
        this.vacationMaster.vacationYear = vacationMaster.vacationYear
        this.vacationMaster.version = vacationMaster.version

        this.setOtherVacationData(vacationMaster)

        if (!this.$route.params.id) {
          this.tryAddNewRequest()
        }

        this.showOptionsForWS14x14 = false
        eventBus.$emit(Events.LongOperationCompleted)
      },
      setOtherVacationData(vacationMaster) {
        if (!vacationMaster.vacationAllowanceRequest) {
          this.vacationMaster.vacationAllowanceRequest.payMonth = Enums.Months.December.value
        }
        else {
          this.vacationMaster.vacationAllowanceRequest.id = vacationMaster.vacationAllowanceRequest.id
          this.vacationMaster.vacationAllowanceRequest.payMonth = vacationMaster.vacationAllowanceRequest.payMonth
          this.vacationMaster.vacationAllowanceRequest.version = vacationMaster.vacationAllowanceRequest.version
        }

        if (vacationMaster.cashInLieuRequests && vacationMaster.cashInLieuRequests.length) {
          for (const cashInLieuRequest of vacationMaster.cashInLieuRequests.filter(cashInLieuRequest => !cashInLieuRequest.isCancelled)) {
            this.vacationMaster.cashInLieuRequests.push(cashInLieuRequest)
          }
        }

        if (vacationMaster.vacationRequests && vacationMaster.vacationRequests.length) {
          let existingRequests = vacationMaster.vacationRequests.filter((er) => !er.isCancelled)
          this.vacationMaster.existingVacationRequests = [...existingRequests]
          for (const previousSchedule of existingRequests) {
            if (this.$route.params.id && previousSchedule.id == this.$route.params.id && this.canEditRequest(previousSchedule)) {
              previousSchedule.startDate = fromServerDate(previousSchedule.startDate)
              previousSchedule.endDate = fromServerDate(previousSchedule.endDate)
              previousSchedule.resumptionDate = fromServerDate(previousSchedule.resumptionDate)
              this.intendedApproverId = previousSchedule.intendedApproverId
              this.intendedApproverName = previousSchedule.intendedApproverName
              this.additionalData.startingUscheduledVacationDays += previousSchedule.duration
              this.requests.push(previousSchedule)
            }
            else {
              this.previouslyScheduledVacationRequests.push(previousSchedule)
            }
          }
        }
        this.vacationMaster.buyOutDays = (this.employeeInfo.workScheduleId === Enums.WorkSchedule.WS28x28.value || this.vacationMaster.cashInLieuRequests.length) ? this.vacationMaster.unScheduledVacationDays : 0
      },
      generateVacationData() {
        if (this.isEditPage) {
          return
        }
        const self = this
        eventBus.$emit(Events.LongOperationStarted, 'Generating vacation data')
        vacationMasterService.createVacationMasterFor({ employeeId: self.employeeInfo.employeeId, vacationYear: self.vacationMaster.vacationYear }).then((result) => {
          self.setVacationData(result)
        }).catch((error) => {
          console.log(error)
          self.handleError(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      generateCashInLieuRequest() {
        //const self = this
        //eventBus.$emit(Events.LongOperationStarted, 'Generating cash in lieu request')
        //cashInLieuRequestService.schedule({ employeeId: self.employeeInfo.employeeId, vacationYear: self.vacationMaster.vacationYear }).then((result) => {
        //  self.setVacationData(result.vacationMaster)
        //}).catch((error) => {
        //  self.handleError(error)
        //}).finally(() => {
        //  eventBus.$emit(Events.LongOperationCompleted)
        //})
        eventBus.$emit(Events.LongOperationCompleted)
      },
      tryAddNewRequest() {
        if (this.vacationMaster.unScheduledVacationDays > 0 || this.vacationMaster.unscheduledCarriedOverDays > 0) {
          const vacationRequestForm = document.getElementById('vacationRequestForm')
          let isValidForm = false
          if (vacationRequestForm) {
            isValidForm = vacationRequestForm.checkValidity()
            if (isValidForm) {
              vacationRequestForm.classList.remove('was-validated');
            }
          }
          if (!vacationRequestForm || isValidForm) {
            //if (this.employeeInfo.isFieldEmployee && this.previouslyScheduledVacationRequests.length || this.employeeInfo.workScheduleId === Enums.WorkSchedule.WS28x28.value) {
            //  return
            //}
            if (this.employeeInfo.workScheduleId === Enums.WorkSchedule.WS28x28.value) {
              return
            }
            this.requests.push({
              id: uuidv4(),
              startDate: '',
              endDate: '',
              resumptionDate: '',
              duration: null, //this.employeeInfo.isFieldEmployee ? (this.vacationMaster.unScheduledVacationDays >= 28 ? 28 : this.vacationMaster.unScheduledVacationDays) : null,
              holidays: null,
              intendedApproverId: this.intendedApproverId,
              intendedApproverName: this.intendedApproverName,
              requestType: Enums.RequestType.Vacation.value
            })
          }
          if (vacationRequestForm && !isValidForm) {
            vacationRequestForm.classList.add('was-validated');
          }
        }
      },
      submitRequest() {
        const self = this
        self.validateForm().then((validationResult) => {
          if (validationResult) {
            self.closeMessage()
            self.isSavingRequest = true
            //console.log(self.requests)
            let data = { requests: self.requests, employeeId: self.employeeInfo.employeeId, vacationAllowanceMonth: self.vacationMaster.vacationAllowanceRequest.payMonth }
            store.dispatch(SCHEDULE_LEAVE_REQUEST, data)
              .then(() => {
                self.requestState = Enums.RequestState.Submitted
                self.showSuccessMessage('request submitted successfully')
                self.resetData()
                self.isCompleted = true
              })
              .catch((error) => {
                console.log(error)
                self.handleError(error)
              }).finally(() => {
                self.isSavingRequest = false
              })
          }
        })
      },
      async validateForm() {
        let errorMessage = ''
        const approverField = document.getElementById('supv_search')
        if (approverField) {
          let invalidApprover = false
          if (!this.intendedApproverId) {
            errorMessage += "No approver selected \n"
            approverField.setCustomValidity("No approver selected")
            invalidApprover = true
          }
          if (this.intendedApproverId.toLowerCase() == this.employeeInfo.employeeId.toLowerCase()) {
            errorMessage += "You cannot approve your own request \n"
            approverField.setCustomValidity("You cannot approve your own request")
            invalidApprover = true
          }
          if (!invalidApprover) {
            approverField.setCustomValidity("")
          }
        }
        const durationFIelds = document.getElementsByClassName('duration')
        durationFIelds.forEach(field => field.setCustomValidity(''))
        if (this.unScheduledVacationDaysToDisplay < 0) {
          errorMessage += "total duration more than unscheduled days \n"
          durationFIelds.forEach(field => field.setCustomValidity('total duration more than unscheduled days'))
        }
        else if (this.vacationMaster.unscheduledVacationDays < 0) {
          if (this.unScheduledVacationDaysToDisplay >= 0) {
            if (this.employeeInfo.isFieldEmployee) {
              errorMessage += "field employees cannot utilize carried over days \n"
            }
            else {
              errorMessage += "Carried over days cannot be used after march \n"
            }
          }
          else {
            errorMessage += "total duration more than unscheduled days \n"
          }
          durationFIelds.forEach(field => field.setCustomValidity('total duration more than unscheduled days'))
        }
        const datesWithCollision = [...durationFIelds].filter(field => field.classList.contains('collission'))
        if (datesWithCollision.length) {
          errorMessage += "there is a date collission with a previously scheduled request. kindly correct all invalid entries \n"
        }
        datesWithCollision.forEach(field => field.setCustomValidity('there is a date collission with a previously scheduled request. kindly correct all invalid entries'))
        const vacationRequestForm = document.getElementById('vacationRequestForm')
        if (vacationRequestForm.checkValidity() === false) {
          vacationRequestForm.classList.add('was-validated');
          var errorElements = document.querySelectorAll(
            "input:invalid");
          $('html, body').animate({
            scrollTop: $(errorElements[0]).offset().top - 20
          }, 500);
          this.showErrorMessage(errorMessage)
          return false
        }
        const approverDirectReports = await employeeService.getEmployeeDirectReports(this.intendedApproverId)
        if (approverDirectReports && approverDirectReports.length) {
          return true
        }
        else {
          this.showErrorMessage('invalid approver. approver is not a supervisor')
          return false
        }
      },
      editRequest(request) {
        this.previouslyScheduledVacationRequests = this.previouslyScheduledVacationRequests.filter((prevRequest) => prevRequest.id != request.id)
        request.startDate = fromServerDate(request.startDate)
        request.endDate = fromServerDate(request.endDate)
        request.resumptionDate = fromServerDate(request.resumptionDate)
        this.additionalData.startingUscheduledVacationDays += request.duration
        this.requests.unshift(request)
      }
    },
    beforeRouteEnter(to, from, next) {
      //eventBus.$emit(Events.LongOperationStarted, 'loading public holidays')
      const tasks = []
      tasks.push(store.dispatch(FETCH_CODETYPES))
      tasks.push(store.dispatch(FETCH_PUBLICHOLIDAYS, new Date().getFullYear()))
      Promise.all(tasks).then(() => {
        next(vm => {
          vm.fetchEmployeeData(store.state.userModule.currentUser)
        })
      }).catch((error) => {
        next(vm => {
          console.log(error)
          self.handleError(error)
        })
      }).finally(() => {
        //  eventBus.$emit(Events.LongOperationCompleted)
      })
    },
    mounted() {
      const currentYear = new Date().getFullYear()
      this.additionalData.vacationYears.push(currentYear)
      if (new Date().getMonth() >= this.appSettings.vacation.nextYearScheduleStartMonth - 1) {
        this.additionalData.vacationYears.push(currentYear + 1)
      }
      this.vacationMaster.vacationYear = currentYear
      eventBus.$on(EDIT_REQUEST_EVENT, this.editRequest)
    }
  }
</script>
